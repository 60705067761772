const IconsReverse = new Set<Icon>([
  'avatarnext',
  'avatarprevious',
  'arrow_next',
  'channel',
  'chatspinned',
  'fast_forward',
  'fast_rewind',
  'forward',
  'forward_filled',
  'group',
  'group_filled',
  'left',
  'listscreenshare',
  'logout',
  'muted',
  'newchannel_filled',
  'newchannel',
  'newgroup',
  'newgroup_filled',
  'next',
  'nosound',
  'previous',
  'send',
  'send2',
  'reply',
  'reply_filled',
  'sharescreen_filled',
  'transcribe',
  'storyreply',
  'premium_noads',
  'topics',
  'pinlist',
  'deleteleft'
]);

export default IconsReverse;
